<!-- eslint-disable -->
<template>
  <loading :active="isLoading" :is-full-page="fullPage" />
  <template v-if="!isLoading">
    <div class="row">
      <div class="col-md-8">
        <div v-show="!isRegister" class="widget white-bg friends-group clearfix">
          <small class="text-muted">Nama Kursus / Course </small>
          <p>{{ dataCourse.fullname }}</p>
          <small class="text-muted">Nama Pengajar </small>
          <div class="pengajar">
            <p v-for="contact in dataCourse.contacts" :key="contact.id">{{ contact.fullname }}</p>
          </div>
          <small class="text-muted">Nama Peserta </small>
          <p>{{ profil.lastname }}</p>
          <small class="text-muted">Email Terdaftar di SPADA</small>
          <p>{{ profil.email }}</p>

          <small class="text-muted"></small>
          <button type="button" v-on:click="onBackPage" class="btn btn-warning btn-rounded box-shadow"><i class="fa fa-chevron-left"></i></button> &nbsp;
          <button type="button" v-on:click="enrollCourse" class="btn btn-success btn-rounded box-shadow">Enroll Course</button> &nbsp;
          <button type="button" v-on:click="unEnrollCourse" class="btn btn-danger btn-rounded box-shadow">Unenroll Course</button> &nbsp;

        </div>
        <div class="card" v-show="isRegister">
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted">
              Halaman Informasi Akun SPADA
            </p>
          </div>
          <div class="card-body padding-t-0">
            <div class="alert bg-warning alert-dismissible" role="alert"> <button type="button" class="close"
                data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>Saudara belum memiliki
              Akun SPADA UM Metro, Klik tombol Registrasi Akun! </div>
            <router-link to="/registrasi-akun-spada" v-show="isRegister" href="javascript: void(0);"
              class="btn btn-success btn-rounded box-shadow">Registrasi Akun</router-link>
          </div>
        </div>
      </div>
    </div>
  </template>
    
</template>

<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
/* global $ */
import Loading from 'vue-loading-overlay'
import { getAkun, getDetailCourse, addPeserta, dltPeserta } from '@/services/spada.service'
import { useStore } from 'vuex'
export default {
  name: 'SpadaInfoAkun',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      userName: users.username,
      profil: {},
      dataCourse: {},
      isCourse: true,
      isRegister: false,
      loadAkun: false,
      loadCourse: false,
      loadEnroll: false,
      onLoading: false,
      fullPage: true,
      messages: ''
    }
  },
  computed: {
    isLoading: function () {
      return !!((this.loadAkun || this.loadCourse || this.loadEnroll))
    }
  },
  created () {
    this.handleAkun()
    this.handleCourse()
  },
  methods: {
    async handleAkun () {
      this.loadAkun = true
      getAkun({ username: this.userName }).then(response => {
        const rows  = response.records
        const users = rows.users
        if (users.length === 0) {
          this.isRegister = true
        } else {
          this.profil = users[0]
        }
        this.loadAkun = false
      }).catch(error => {
        this.messages = (error.response) ? {} : {}
        this.loadAkun = false
      })
    },
    async handleCourse () {
      this.loadCourse = true
      getDetailCourse({ kode: this.$route.params.kode }).then(response => {
        const rows  = response.records
        const courses = rows.courses
        if (courses.length === 0) {
          this.isCourse = false
        } else {
          this.dataCourse = courses[0]
        }
        this.loadCourse = false
      }).catch(error => {
        this.messages = (error.response) ? {} : {}
        this.loadCourse = false
      })
    },
    onBackPage () {
      this.$router.push('/list-course-spada')
    },
    async enrollCourse () {
      this.loadEnroll = true
      addPeserta({ course: this.$route.params.id, user: this.profil.id, role: 5 })
        .then(response => {
          if (response.status === 200) {
            $.toast({
              heading: 'Good job!',
              text: 'Pendaftaran kelas berhasil',
              position: 'top-right',
              loaderBg: '#fff',
              icon: 'success',
              hideAfter: 3000,
              stack: 1
            })
          } else {
            $.toast({
              heading: 'Error !',
              text: response.messages,
              position: 'top-right',
              loaderBg: '#fff',
              icon: 'error',
              hideAfter: 3000,
              stack: 1
            })
          }
          this.loadEnroll = false
        }).catch(error => {
          this.messages = (error.response) ? {} : {}
          this.loadEnroll = false
        })
    },
    async unEnrollCourse () {
      this.loadEnroll = true
      dltPeserta({ course: this.$route.params.id, user: this.profil.id, role: 5 })
        .then(response => {
          if (response.status === 200) {
            $.toast({
              heading: 'Good job!',
              text: 'Pembatalan kelas berhasil',
              position: 'top-right',
              loaderBg: '#fff',
              icon: 'success',
              hideAfter: 3000,
              stack: 1
            })
          } else {
            $.toast({
              heading: 'Error !',
              text: response.messages,
              position: 'top-right',
              loaderBg: '#fff',
              icon: 'error',
              hideAfter: 3000,
              stack: 1
            })
          }
          this.loadEnroll = false
        }).catch(error => {
          this.messages = (error.response) ? {} : {}
          this.loadEnroll = false
        })
    }
  }
}
</script>
