<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted" >
              Halaman Informasi Kelas / Course yang sudah ada di SPADA UM Metro
            </p>
          </div>
          <div class="card-body padding-t-0 ">
            <div class="table-responsive">
              <table id="datatable" class="table no-footer dt-responsive nowrap" style="width:100%">
                <thead>
                  <tr>
                    <th style="width:20px">
                      <strong>No.</strong>
                    </th>
                    <th>
                      <strong>Nama Kelas / Course</strong>
                    </th>
                    <th>
                      <strong>Pengajar</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, num) in kelas" :key="row.id">
                    <td>{{ num+1 }} </td>
                    <td>
                      {{ row.fullname }} <br>
                      <router-link :to="{name: 'infocoursespada', params: { id: row.id, kode: row.idnumber} }" class="btn btn-success"><i class="fa fa-reply"></i> &nbsp;Detail</router-link>
                    </td>
                    <td>
                      <p v-for="contact in row.contacts" :key="contact.id">
                        <img alt="user" class="media-box-object rounded-circle mr-2" src="/img/avtar-2.png" width="30"> {{ contact.fullname }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3></h3>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { useStore } from 'vuex'
import { getKelas } from '@/services/spada.service'
export default {
  name: 'SpadaListCourse',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user

    return {
      userName: users.username,
      kelas: [],
      isLoading: false,
      fullPage: true
    }
  },
  created () {
    this.getAllCourse()
  },
  methods: {
    async getAllCourse () {
      this.isLoading = true
      getKelas({ username: this.userName }).then(response => {
        const records = response.records
        const course = records.courses
        if (course.length === 0) {
          this.isRegister = true
        } else {
          this.kelas = course
        }
        this.isLoading = false
      }).catch(error => {
        this.kelas = (error.response) ? {} : {}
        this.isLoading = false
      })
    }
  }
}
</script>
